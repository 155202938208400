import {
  openSubMenu
} from './poolservice/navigation';
import {
  bannerCardsSetWidth
} from './poolservice/banner';
import './poolservice/news';

// Global variables


// Init actions
openSubMenu();
bannerCardsSetWidth();

// On window resize
window.addEventListener('resize', () => {
  bannerCardsSetWidth();
});
