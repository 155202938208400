function bannerCardsSetWidth () {
  const pictures = document.querySelectorAll('[data-banner-pic]'),
        windowWidth = window.innerWidth;

  if (pictures) {
    for (let i = 0; i < pictures.length; i++) {
      let picture = pictures[i],
          rectLeft = picture.getBoundingClientRect().left;
      picture.style.width = windowWidth - rectLeft + 'px';
    }
  }
}

export {
  bannerCardsSetWidth
}
